/**
 * @see https://dexie.org/docs/
 */
import Dexie from 'dexie';

import {
  type BudgetSeedDto,
  type CardMockModel,
  type CounterpartySeedDto,
  type OverviewSeedDto,
  genBudgets,
  genCardCredentials,
  genCards,
  genCompany,
  genCompanyAccounts,
  genCompanyUsers,
  genCoreUserMe,
  genCoreUsers,
  genCounterparties,
  genOverviewStats,
  genTransactions,
} from '@/seeds';

import { CompanyUserRole } from '@/enums';

import type {
  BudgetUser,
  BudgetUserInBudget,
  CardCredentials,
  Company,
  CompanyAccount,
  CompanyUser,
  CoreUser,
  CoreUserMe,
  CounterpartyPaymentAccountData,
  InvoiceDto,
  InvoiceItemDto,
  Transaction,
} from '@/interfaces';

const USERS_COUNT = 40;
const TRANSACTIONS_COUNT = 10000;

export class DemoDatabase extends Dexie {
  public companies: Dexie.Table<Company, number>;
  public companyAccounts: Dexie.Table<CompanyAccount, number>;
  public userMe: Dexie.Table<CoreUserMe, number>;
  public users: Dexie.Table<Partial<CoreUser>, number>;
  public companyUsers: Dexie.Table<CompanyUser, number>;
  public budgetUsers: Dexie.Table<BudgetUser, number>;
  public budgetUsersInBudgets: Dexie.Table<BudgetUserInBudget, number>;
  public cards: Dexie.Table<CardMockModel, number>;
  public cardCredentials: Dexie.Table<CardCredentials, number>;
  public budgets: Dexie.Table<BudgetSeedDto, number>;
  public transactions: Dexie.Table<Transaction, number>;
  public counterparties: Dexie.Table<CounterpartySeedDto, number>;
  public counterpartyPaymentAccounts: Dexie.Table<
    CounterpartyPaymentAccountData,
    number
  >;

  public invoices: Dexie.Table<InvoiceDto, number>;
  public invoiceItems: Dexie.Table<InvoiceItemDto, number>;

  public overviewStats: Dexie.Table<OverviewSeedDto, number>;

  /**
   * Нужно бампать версию каждый раз при изменении моделей, полей, логики или сидов
   * При изменений версии произойдет следующее:
   * 1. Полное удаление старой БД из indexedDB
   * 2. Создание новой версии
   * 3. Накатывание сидов (событие 'populate')
   */
  private readonly targetVersion: number = 69;

  constructor() {
    super('DemoDatabase');
  }

  async init() {
    /**
     * Проверяем если ли вообще БД у пользователя (самый первый запуск приложения)
     */
    const isExist = await Dexie.exists('DemoDatabase');

    /**
     * Если база существует, то нужно сверить ее версию приложения с актуальной (targetVersion)
     */
    if (isExist) {
      /**
       * Открываем тестовый инстанс БД для проверки версий
       */
      await this.open();
      if (this.verno < this.targetVersion) {
        /**
         * Если версия устарела, то удаляем полностью БД и создаем ее с нуля
         * При этом отработает событие 'populate
         */
        await this.delete();
      } else {
        /**
         * Если версия актуальная, то просто закрываем тестовый инстанс БД
         * Событие 'populate' не сработает, сиды останутся старыми
         */
        this.close();
      }
    }

    this.version(this.targetVersion).stores({
      companies: '++id, name, status, auth0Id',
      companyAccounts:
        '++id, name, type, description, accountNumber, currency, balance, spendThisMonth, routingNumberAch, routingNumberWire, beneficiaryName, beneficiaryAddress, bankName, bankAddress, daysBeforeMoneyOver, cardIssuingReason',
      userMe:
        '++id, firstName, lastName, avatar, role, status, timezone, emailVerified, *permissions, phoneVerified, *companies',
      users:
        '++id, firstName, lastName, avatar, status, username, nickname, auth0Id',
      companyUsers:
        '++id, user.fullName, user.id, user, status, role, *budgetUsers, spend',
      budgetUsers:
        '++id, user.fullName, user.id, budget.id, [budget.id+user.id], user, role, *limits',
      budgetUsersInBudgets: '++id, budgetId, userId',
      cards:
        '++id, name, panLast4, type, status, cardHolder, user, budgetId, companyAccountId, *limits, spend, billingAddress, createdAt, currency',
      cardCredentials: '++id, pan, expiration, cvv',
      budgets:
        '++id, name, purpose, managers, userCount, *limits, status, companyAccount, spend, currency, createdAt, cardIssuingReason',
      transactions:
        '++id, type, chargeType, status, amount, totalAmount, hasFee, companyAccount, budget, budget.id, [budget.id+status], [budget.id+user.id+status], card, user, currency, mcc, merchant, merchantRaw, description, providerDeclineReason, declineReason, authorizedAt',
      counterparties:
        '++id, name, businessType, email, country, logo, invoicePrefix, spend, transactionsSum, lastTransactionDate',
      counterpartyPaymentAccounts:
        '++id, legalName, counterpartyId, routingNumber, accountNumber, country, state, city, addressLine1, addressLine2, zipCode, logo',
      invoices:
        '++id, counterparty, email, invoiceItems, dueDate, status, number, totalPrice, urlPdf, counterpartyCompany, counterpartyCompanyAddress, counterpartyCompanyName, counterpartyCompanyPhone, counterpartyCountry, counterpartyName',
      invoiceItems: '++id, invoiceId, name, quantity, price',
      overviewStats: '++id, *date, value, currency, isConverted',
    });

    /**
     * Событие для наполнения БД начальными значениями.
     * Отрабатывает только 1 раз при создании новой БД
     */
    this.on('populate', (tr: DemoDatabase) => {
      const company = genCompany();
      const companyAccounts = genCompanyAccounts();
      const users = genCoreUsers(USERS_COUNT);
      const { budgets, budgetUsers, budgetUsersInBudgets } = genBudgets(
        USERS_COUNT,
        users,
        companyAccounts[0],
      );

      const companyUsers = genCompanyUsers(users, budgetUsers);
      const userMe = genCoreUserMe(
        [company],
        companyUsers.find(u => u.role === CompanyUserRole.Owner) ??
          companyUsers[0],
      );

      const cards = genCards(budgetUsers, userMe, company);
      const cardCredentials = genCardCredentials(cards.length);

      const counterparties = genCounterparties();

      const transactions = genTransactions(
        TRANSACTIONS_COUNT,
        cards,
        budgets,
        companyAccounts[0],
        counterparties,
      );

      const overviewStats = genOverviewStats();

      tr.companies.add(company);
      tr.companyAccounts.bulkAdd(companyAccounts);
      tr.userMe.add(userMe);
      tr.users.bulkAdd(users);
      tr.companyUsers.bulkAdd(companyUsers);
      tr.budgetUsers.bulkAdd(budgetUsers);
      tr.budgetUsersInBudgets.bulkAdd(budgetUsersInBudgets);
      tr.cards.bulkAdd(cards);
      tr.cardCredentials.bulkAdd(cardCredentials);
      tr.budgets.bulkAdd(budgets);
      tr.transactions.bulkAdd(transactions);
      tr.counterparties.bulkAdd(counterparties);
      tr.invoices.bulkAdd([]);
      tr.invoiceItems.bulkAdd([]);
      tr.overviewStats.bulkAdd(overviewStats);
    });

    await this.open();
  }
}

export const db = new DemoDatabase();
